<!-- 在微信里面引导去浏览器打开图层 -->
<template>
  <div>
    <van-overlay :show="show" class="dialog">
      <div class="wrapper" @click.stop>
        <div class="divBg">
          <div class="flex_center font-color1B1B1B font-size36 font-weightBold margin-top40 line-height50">
            关联X世界账号
          </div>
          <div class="flex_center font-color999999 font-size28 margin-top10 line-height40">
            关联后可前往X世界APP领取火箭值
          </div>
          <div class="margin-left34 margin-right34"
               style="border-radius: var(--10);background:#F3F4F6;
              padding-left: var(--30);padding-right: var(--30);margin-top: var(--40);">
            <van-field v-model="userMobile" placeholder="请输入X世界账号" clearable
                       placeholder-class='placeholder-input'/>
          </div>
          <div class="margin-left34 margin-right34"
               style="border-radius: var(--10);background:#F3F4F6;
              padding-left: var(--30);padding-right: var(--30);margin-top: var(--20);">
            <van-field v-model="userPassword" placeholder="请输入账号密码" clearable type="password"
                       placeholder-class='placeholder-input'/>
          </div>
          <div class="margin-top40 flex_center font-size28 line-height40" style="color:#FF3B63;">
            关联成功后不可解绑，请核实后再进行关联!
          </div>
          <div class="flex_center margin-top20">
            <div @click="show = false"
                 class="flex_center font-color666666 font-size30 line-height42"
                 style="flex: 1;height: var(--88);background: #EEEEEE;border-radius: var(--44);margin-left: var(--32);">
              取消
            </div>
            <div @click="clickSure" class="flex_center margin-left22 font-colorFFFFFF font-size30"
                 style="flex: 1;height: var(--88);background: #026AFC;border-radius: var(--44);margin-right: var(--32);">
              确定
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>

import {Toast} from "vant";
import EncryptUtils from "@/utils/EncryptUtils";
import {Api} from "@/utils/net/Api";

export default {
  props: {
    show: {}
  },
  data() {
    return {
      userMobile: "",
      userPassword: ""
    }
  },
  methods: {
    showDialog(){
      this.userMobile = ""
      this.userPassword = ""
      this.show = true
    },
    clickSure() {
      Toast.loading({
        forbidClick: true,
        duration: 0
      });
      this.userContactAddBind()
    },
    userContactAddBind() {
      if (this.userMobile.length <= 0) {
        Toast("请输入账号")
        return
      }
      if (this.userPassword.length <= 0) {
        Toast("请输入密码")
        return
      }
      let param = {
        userMobile: this.userMobile,
        passWord: EncryptUtils.getEncryptLoginPwd(this.userPassword)
      }
      Api.userContactAddBind(param).then(res => {
        if (res.code === 100) {
          console.log(res.data)
          this.show = false
          this.$emit('bindSuccess')
          Toast.clear()
        } else {
          Toast(res.msg);
        }
      })
    },
  },
  created() {
  },
}
</script>

<style scoped>
.dialog {
  z-index: 99;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.divBg {
  width: var(--630);
  height: var(--636);
  background: #FFFFFF;
  border-radius: var(--10);
}

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #999999;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--100);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1b1b1b;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}
</style>
