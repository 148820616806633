<template>
  <div class="body">
    <div v-if="showOpenAuthorize" @click="openAuthorize" class="settingItem">
      <span class="settingItemTitle" style="flex: 1;">
        关联X世界账号
      </span>
      <span class="settingItemTitle" style="font-size: var(--28);">

      </span>
      <img class="settingBack" src="@/common/img/setting/back.png" alt="">
    </div>
    <div v-if="!showOpenAuthorize" class="settingItem">
      <span class="settingItemTitle" style="flex: 1;">
        X世界账号:
      </span>
      <span class="settingItemTitle" style="font-size: var(--28);">
        {{ bindId }}
      </span>
      <img v-if="false" class="settingBack" src="@/common/img/setting/back.png" alt="">
    </div>
    <div style="display:flex;flex-direction: row;">
      <div @click="environment('1')" class="settingInputPassword">

      </div>
      <div @click="environment('2')" class="settingInputPassword">

      </div>
      <div @click="environment('3')" class="settingInputPassword">

      </div>
    </div>
    <associationXWorldApp ref="associationXWorldApp" @bindSuccess="bindSuccess"/>
  </div>
</template>

<script>

import {Api} from "@/utils/net/Api";
import EncryptUtils from "@/utils/EncryptUtils";
import associationXWorldApp from "@/pages/app/home/components/associationXWorldApp.vue";
import {Constants} from "@/utils/constants";

export default {
  components: {associationXWorldApp},
  computed: {
    EncryptUtils() {
      return EncryptUtils
    }
  },
  data() {
    return {
      userMobile: "",
      bindId: "",//显示关联入口
      showOpenAuthorize: true,//显示关联入口
      inputContentPlaceholder: "",
      inputContentDialogTitle: "",
      itemSelectObj: {},
      popupExplain: false,
      userObj: {},
      itemList: [
        {
          title: "设置联系方式",
          updateKey: "otcContactInfo"
        },
        {
          title: "设置姓名",
          updateKey: "otcPayName"
        },
        {
          title: "设置微信号",
          updateKey: "otcPayWx"
        },
        {
          title: "设置支付宝账号",
          updateKey: "otcPayAli"
        },
        {
          title: "设置银行卡号",
          updateKey: "otcPayBankNum"
        }, {
          title: "设置银行名称",
          updateKey: "otcPayBank"
        },
      ],
      mEnvNum: "",
      mPassword: "1133123321",
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "设置"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "true",
        statusBarBgColor: "#ffffff",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    if (this.$route.query.userNum) {
      this.userNum = this.$route.query.userNum;
    }
    let userMobile = localStorage.getItem(Constants.userMobile);
    if (!EncryptUtils.isEmpty(userMobile)) {
      this.userMobile = userMobile
    }
    this.userContactQueryOne()
  },
  mounted() {
    window.openCameraResult = this.openCameraResult;
  },
  methods: {
    environment(num) {
      this.mEnvNum = this.mEnvNum + num
      if (this.mEnvNum == this.mPassword) {
        this.$router.push({
          path: '/debugPage',
          query: {}
        });
      }
    },
    userContactQueryOne() {
      let params = {}
      Api.userContactQueryOne(params).then((result) => {
        if (result.code === 100) {
          if (EncryptUtils.isEmpty(result.data.entity)) {
            this.showOpenAuthorize = true
          } else {
            this.showOpenAuthorize = false
            this.bindId = result.data.entity.bindId
          }
        } else {
          this.showOpenAuthorize = true
          // Toast(result.msg);
        }
      })
    },
    bindSuccess() {
      this.userContactQueryOne()
    },
    //关联X世界账号
    openAuthorize() {
      this.$refs.associationXWorldApp.showDialog()
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.settingItem {
  margin-left: var(--30);
  margin-right: var(--30);
  display: flex;
  align-items: center;
  height: var(--104);
  border-bottom: var(--2) solid #F8F8F8;
}

.settingBack {
  width: var(--36);
  height: var(--36);
}

.settingItemTitle {
  font-size: var(--30);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1B1B1B;
  line-height: var(--42);
}

.settingInputPassword {
  margin: var(--2);
  flex: 1;
  height: var(--200);
}

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #999999;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--100);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1b1b1b;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}
</style>
